import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import cn from 'classnames';
import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';

// @ts-ignore
import Button from '../../../../shared/components/Button';
import Icon from '../../../../shared/components/Icon';
import Tooltip from '../../../../shared/components/Tooltip';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ChartTooltip,
  Legend,
);

interface MetricChartProps {
  title: string;
  value: number;
  data: number[];
  labels: string[];
}

export default function MetricChart({ title, value, data, labels }: MetricChartProps) {
  const [chartType, setChartType] = useState<'line' | 'bar'>('line');
  const maxValueIndex = data.indexOf(Math.max(...data));
  const chartData = {
    labels,
    datasets: [
      {
        data,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        backgroundColor: (context: any) => {
          const index = context.dataIndex;
          const isHovered = context.active;
          if (chartType === 'line') {
            return 'rgba(153, 205, 255, 0.1)';
          }

          if (index === maxValueIndex) {
            return isHovered ? '#006ACC' : '#004F99';
          }

          return isHovered ? '#006ACC' : '#DDE3E7';
        },
        borderColor: chartType === 'line' ? '#004F99' : '#DDE3E7',
        borderWidth: 2,
        fill: true,
        pointRadius: chartType === 'line' ? 4 : 0,
        tension: 0.4,
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customTooltip = (context: any) => {
    let tooltipEl = document.getElementById('chartjs-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.backgroundColor = '#004F99';
      tooltipEl.style.color = '#FFF';
      tooltipEl.style.borderRadius = '4px';
      tooltipEl.style.padding = '4px 12px';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.fontFamily = 'Nunito Sans';
      tooltipEl.style.fontSize = '12px';
      tooltipEl.style.fontWeight = '700';
      tooltipEl.style.lineHeight = 'normal';
      tooltipEl.style.transition = 'opacity 0.2s ease';
      tooltipEl.style.opacity = '0';
      tooltipEl.style.zIndex = '1000';

      const textContainer = document.createElement('div');
      textContainer.id = 'tooltip-text';
      tooltipEl.appendChild(textContainer);

      const arrow = document.createElement('div');
      arrow.id = 'tooltip-arrow';
      arrow.style.position = 'absolute';
      arrow.style.width = '0';
      arrow.style.height = '0';
      arrow.style.borderLeft = '6px solid transparent';
      arrow.style.borderRight = '6px solid transparent';
      arrow.style.borderTop = '6px solid #004F99';
      arrow.style.bottom = '-6px';
      arrow.style.left = '50%';
      arrow.style.transform = 'translateX(-50%)';
      tooltipEl.appendChild(arrow);

      document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;
    if (!tooltipModel || tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    const dataPoint = tooltipModel.dataPoints[0];
    const textContainer = document.getElementById('tooltip-text');
    if (textContainer) {
      textContainer.innerHTML = `$${dataPoint.raw.toLocaleString()}`;
    }

    const canvasRect = context.chart.canvas.getBoundingClientRect();
    const tooltipWidth = tooltipEl.offsetWidth;
    const tooltipHeight = tooltipEl.offsetHeight;

    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = `${
      canvasRect.left + window.scrollX + tooltipModel.caretX - tooltipWidth / 2
    }px`;
    tooltipEl.style.top = `${
      canvasRect.top + window.scrollY + tooltipModel.caretY - tooltipHeight - 10
    }px`;
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number) => `${value / 1000}K`,
        },
      },
      x: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        external: customTooltip,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      className="loans-overview__metric-section card"
      style={{ padding: 24, width: '100%', gap: 0, flexDirection: 'column' }}
    >
      <h3
        className="loans-overview__metric-section__title"
        style={{
          fontSize: 20,
          color: '#607180',
          fontWeight: 400,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
          }}
        >
          {title}

          <Tooltip
            title={title}
            text="This chart displays the total amount of bills paid to you by your customers' friends or family during the selected time period, excluding Zirtue's service fees."
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon name="info-circle-solid-outline" classes="table-dots__icon" />
            </div>
          </Tooltip>
        </div>
        <div className="loans-overview__metric-section__chartSwitchButtons">
          <Button
            onClick={() => setChartType('line')}
            className={cn(
              'button loans-overview__metric-section__chartSwitchButton',
              chartType === 'line'
                ? 'loans-overview__metric-section__chartSwitchButton__isActive'
                : '',
            )}
            icon="line-chart"
          >
            Line
          </Button>
          <Button
            onClick={() => setChartType('bar')}
            className={cn(
              'button loans-overview__metric-section__chartSwitchButton',
              chartType === 'bar'
                ? 'loans-overview__metric-section__chartSwitchButton__isActive'
                : '',
            )}
            icon="bar-chart"
          >
            Bar
          </Button>
        </div>
      </h3>

      {/* eslint-disable-next-line */}
      <p
        className="loans-overview__metric-section__analytic-value"
        style={{ marginTop: 8, marginBottom: 24, fontSize: 40 }}
      >
        ${value.toLocaleString()}
      </p>
      <div style={{ height: '300px', width: '100%' }}>
        {chartType === 'line' ? (
          <Line
            style={{ maxHeight: '100%', maxWidth: '100%' }}
            data={chartData}
            // @ts-ignore
            options={options}
          />
        ) : (
          <Bar
            style={{ maxHeight: '100%', maxWidth: '100%' }}
            data={chartData}
            // @ts-ignore
            options={options}
          />
        )}
      </div>
    </div>
  );
}
