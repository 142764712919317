import Notification from '../../loans/components/OverView/Notification';
import { LoanAnalyticsDto } from '../../loans/store';

import LineChartSection from './Charts/LineChartSection';
import LineChartSectionGraph from './Charts/LineChartSectionGraph';
import MetricChart from './Charts/MetricChart';
import Filters from './Filters';

interface OverviewMetricsProps {
  loanAnalytics: LoanAnalyticsDto;
  handleFilterChange: (value: { periodTime: string[]; receivableType: string }) => void;
}

export default function OverviewMetrics({
  loanAnalytics: {
    totalLoansCreated,
    totalLoansAccepted,
    totalLoansRepaid,
    delinquencyRate,
    reasonsForLoans,
    /* paymentArrangementsSent,
       paymentArrangementsAccepted,
       averagePaymentAmount,
       averageArrangementDuration,
       totalRevenueRecovered,
       totalOutstandingReceivables */
  },
  handleFilterChange,
}: OverviewMetricsProps) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const totalRevenueData = [
    5000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000,
  ];

  return (
    <div style={{ marginBottom: 24 }} className="card card--shadow">
      <Notification />

      <Filters isReasonLabel={false} disabled={false} onChange={handleFilterChange} />
      <section className="loans-overview__metric-section" style={{ flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex', gap: 24 }}>
          <LineChartSection
            title="Zirtue Pay Clicks"
            iconName="blue-cursor-click"
            value={316}
            percent={25}
          />
          <LineChartSection
            title="Zirtue Downloads"
            iconName="blue-download"
            value={316}
            percent={25}
          />
          <LineChartSection
            title="Zirtue Registrations"
            iconName="blue-registration"
            value={124}
            percent={25}
          />
        </div>

        <div style={{ width: '100%', display: 'flex', gap: 24 }}>
          <LineChartSectionGraph title="Payment Requests" value={400} percent={12} />
          <LineChartSectionGraph title="View Payments Processed" value={124} percent={12} />
          <LineChartSectionGraph title="Average Payment Amount" value={121.32} percent={25} />
        </div>

        <div style={{ width: '100%' }}>
          <MetricChart
            title="Bill Pay Revenue"
            value={72000} // Mock data
            data={totalRevenueData}
            labels={months}
          />
        </div>
      </section>
    </div>
  );
}
