import { useFlag } from '@unleash/proxy-client-react';
import has from 'lodash/has';
import { FC, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { FlowTypes } from '../../features/auth/store';
import { FeatureTogglesNames } from '../../main/env/feature-toggle-names';
import { useAppSelector } from '../../main/store/hooks';
import { ORG_ADMIN, Permissions } from '../constants/Permissions';

type PermissonsPredicateOr = { $or: (PermissonsPredicateAnd | Permissions)[] };
type PermissonsPredicateAnd = { $and: (PermissonsPredicateOr | Permissions)[] };

export type PermissonsPredicate = PermissonsPredicateAnd | PermissonsPredicateOr;

const isOr = (val: PermissonsPredicate): val is PermissonsPredicateOr => has(val, '$or');
const isAnd = (val: PermissonsPredicate): val is PermissonsPredicateAnd => has(val, '$and');
const isPermission = (val: PermissonsPredicate | Permissions): val is Permissions =>
  typeof val === 'string';

const verifyPermissions = (
  orgPermissions: string[],
  predicate: PermissonsPredicate | Permissions,
): boolean => {
  if (isPermission(predicate)) return orgPermissions.includes(predicate);
  if (isOr(predicate)) {
    return !!predicate.$or.find((p) => verifyPermissions(orgPermissions, p));
  }
  if (isAnd(predicate)) {
    return predicate.$and.every((p) => verifyPermissions(orgPermissions, p));
  }
  return false;
};

interface PermissionsGuardProps {
  permissions?: PermissonsPredicate;
  bypassForAdmin?: boolean;
  redirect?: string;
  title?: string;
}

/**
 * Renders children
 * - if authenticated user's permissions match the given permissions predicate
 * - if `bypassForAdmin` prop is not `false` and authenticated user has `ORG_ADMIN` role
 *
 * If redirect prop is provided,
 * it will render Redirect with given value instead of just hiding the children
 */
export const PermissionsGuard: FC<PermissionsGuardProps> = ({
  permissions,
  bypassForAdmin = true,
  children,
  redirect,
  title,
}) => {
  const { finishedSetup, authToken, partner, checklistFinished } = useAppSelector(
    (state) => state.auth,
  );
  const { orgPermissions, orgRoles } = authToken ?? {};

  const PARTNER_DBP_WEBHOOKS = useFlag(FeatureTogglesNames.PARTNER_DBP_WEBHOOKS);
  const PARTNER_P2P_WEBHOOKS = useFlag(FeatureTogglesNames.PARTNER_P2P_WEBHOOKS);
  const PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD = useFlag(
    FeatureTogglesNames.PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD,
  );
  const PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD = useFlag(
    FeatureTogglesNames.PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD,
  );
  const PARTNER_DBP_DEVELOPER_TOOLKIT = useFlag(FeatureTogglesNames.PARTNER_DBP_DEVELOPER_TOOLKIT);
  const PARTNER_P2P_DEVELOPER_TOOLKIT = useFlag(FeatureTogglesNames.PARTNER_P2P_DEVELOPER_TOOLKIT);

  const isNotP2P = !(
    partner?.details.flowType === FlowTypes.AFFILIATE_PARTNER ||
    partner?.details.flowType === FlowTypes.DECLINE_FLOW ||
    partner?.details.flowType === FlowTypes.CLIENT_SERVICE
  );

  const canRender = useMemo<boolean>(() => {
    if (!authToken || !orgPermissions || !orgRoles || !partner) {
      return false;
    }

    const isOnboardingPage = window.location.href.endsWith('/init-setup');
    const isIntegrationsPage = window.location.href.includes('/integrations');
    const isDeveloperToolkitPages = window.location.href.includes('/developer-tools');
    const isWebHooksPages = window.location.href.includes('/developer-tools/webhooks');
    const isSftpPages = window.location.href.includes('/developer-tools/sftp');

    if (isOnboardingPage && checklistFinished) return false;
    if (!finishedSetup && !isOnboardingPage && partner.details.flowType) return false;

    if (
      partner.details.flowType === FlowTypes.LOAN_CREATOR &&
      (isDeveloperToolkitPages || isWebHooksPages || isSftpPages)
    ) {
      return false;
    }

    if (
      isWebHooksPages &&
      ((isNotP2P && !PARTNER_DBP_WEBHOOKS) || (!isNotP2P && !PARTNER_P2P_WEBHOOKS))
    ) {
      return false;
    }

    if (
      isSftpPages &&
      ((isNotP2P && !PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD) ||
        (!isNotP2P && !PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD))
    ) {
      return false;
    }

    if (
      isDeveloperToolkitPages &&
      ((isNotP2P && !PARTNER_DBP_DEVELOPER_TOOLKIT) ||
        (!isNotP2P && !PARTNER_P2P_DEVELOPER_TOOLKIT))
    ) {
      return false;
    }

    if (partner.details.flowType === FlowTypes.LOAN_CREATOR && isIntegrationsPage) {
      return false;
    }

    if (!orgRoles || !orgPermissions) return false;
    if (!permissions || (orgRoles.includes(ORG_ADMIN) && bypassForAdmin)) {
      return true;
    }

    const hasRequiredPermissions = verifyPermissions(orgPermissions, permissions);
    return hasRequiredPermissions;
  }, [
    isNotP2P,
    authToken,
    orgPermissions,
    orgRoles,
    permissions,
    bypassForAdmin,
    finishedSetup,
    partner,
    checklistFinished,
    PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD,
    PARTNER_DBP_DEVELOPER_TOOLKIT,
    PARTNER_DBP_WEBHOOKS,
    PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD,
    PARTNER_P2P_DEVELOPER_TOOLKIT,
    PARTNER_P2P_WEBHOOKS,
  ]);

  if (!authToken || !orgPermissions || !orgRoles || !partner) {
    return null;
  }

  if (!canRender && redirect) {
    return <Redirect to={{ pathname: redirect, state: { title } }} />;
  }

  return <>{canRender ? children : null}</>;
};
