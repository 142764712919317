import AnalyticsSection from './AnalyticsSection';
import LineChart from './LineChart';

interface LineChartSectionProps {
  title: string;
  value?: number;
  percent: number;
}
export default function LineChartSectionGraph({
  title,
  value = 0,
  percent,
}: LineChartSectionProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 24,
        height: 182,
        width: 'calc(33.33333% - 13px)',
      }}
      className="card card--shadow"
    >
      <AnalyticsSection title={title} value={value} percent={percent} />
      <LineChart />
    </div>
  );
}
