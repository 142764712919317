import { Icon } from '../../../../shared';

import AnalyticsSection from './AnalyticsSection';

interface LineChartSectionProps {
  title: string;
  value?: number;
  percent: number;
  iconName: string;
}
export default function LineChartSection({
  title,
  value = 0,
  percent,
  iconName,
}: LineChartSectionProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 24,
        height: 182,
        width: 'calc(33.33333% - 13px)',
      }}
      className="card card--shadow"
    >
      <AnalyticsSection title={title} value={value} percent={percent} />
      <div className="loans-overview__lineChart-section__icon">
        <Icon name={iconName} />
      </div>
    </div>
  );
}
