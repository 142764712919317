import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { DateRangeFilter, DateRangeTuple, Select } from '../../../shared';
import Button from '../../../shared/components/Button';
import { getTimePeriod } from '../../../shared/utils';

// interface FiltersProps {
//   isReasonLabel?: boolean;
//   disabled?: boolean;
//   onChange?: (value: { reason: string; periodTime: string[] }) => void;
// }

interface FiltersProps {
  isReasonLabel?: boolean;
  disabled?: boolean;
  onChange?: (value: { periodTime: string[]; receivableType: string }) => void;
}

export default function Filters({ isReasonLabel = true, disabled = true, onChange }: FiltersProps) {
  const history = useHistory();

  const [periodTime, setPeriodTime] = useState<string[]>(getTimePeriod(30));

  const [receivableType, setReceivableType] = useState('Biller');

  useEffect(() => {
    if (onChange) {
      onChange({ periodTime, receivableType });
    }
  }, [periodTime, receivableType, onChange]);

  const handleDateFilter = (value: DateRangeTuple) => {
    setPeriodTime(value);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 15,
        marginBottom: 32,
      }}
    >
      <Button
        className="button button--outline loans-overview__metric-section__filterButton"
        icon="arrow-blue-bold"
        onClick={() => {
          history.push('/payments');
        }}
      >
        View payments
      </Button>

      <div style={{ marginBottom: 0 }} className="table-filter__col">
        <DateRangeFilter
          classes="table-filter__date-filter"
          onChangePeriod={(value) => handleDateFilter(value)}
          placeholder="Select date"
          disabled={disabled}
          showDays={30}
        />
      </div>
    </div>
  );
}
